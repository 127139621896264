<template>
    <div
        :id="listboxId"
        :class="[
            'my-2 w-full overflow-hidden rounded-md text-base shadow-xl ring-1 ring-black ring-opacity-5 sm:text-sm',
            dark ? 'bg-gray-700' : 'bg-white',
        ]"
    >
        <!-- Slot - List Prepend -->
        <div
            v-if="slots['list-prepend']"
            class="overflow-hidden rounded-t-md border-b p-1 shadow"
            @click.capture="onSlotClick"
        >
            <slot name="list-prepend" />
        </div>

        <!-- List options -->
        <ul
            v-if="options.length"
            ref="listbox"
            role="listbox"
            class="max-h-60 overflow-auto p-1"
            @mouseleave.prevent="highlightedOptionIndex = undefined"
        >
            <li
                v-for="(option, index) in options"
                :key="areItemsObjects ? get(option, optionValue) : option"
                role="option"
                :title="getOptionText(option)"
                :[highlightedAttribute]="highlightedOptionIndex === index"
                class="relative cursor-pointer select-none"
                @mouseover.stop="highlightedOptionIndex = index"
                @click.stop="onOptionSelect(option)"
            >
                <slot
                    name="list-item"
                    :item="option"
                    :active="highlightedOptionIndex === index"
                    :selected="isOptionSelected(option)"
                >
                    <div
                        :class="[
                            'flex items-center justify-between rounded-md px-4 py-2 text-left',
                            dark ? 'text-white' : 'text-gray-900',
                            isOptionSelected(option) &&
                                'bg-primary-700 font-bold text-white',
                            highlightedOption === option
                                ? dark
                                    ? 'bg-gray-500'
                                    : 'bg-primary-100'
                                : '',
                        ]"
                    >
                        <span
                            class="truncate"
                            v-html="getOptionDisplayText(option)"
                        />

                        <!-- Check icon -->
                        <span
                            v-if="
                                isOptionSelected(option) && !hideSelectedCheck
                            "
                            class="ml-2 flex shrink-0 items-center"
                        >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </div>
                </slot>
            </li>
        </ul>

        <!-- Slot - Empty -->
        <slot v-else name="empty">
            <div
                :class="[
                    'flex h-9 select-none items-center justify-center space-x-2 px-4 text-center',
                    dark ? 'text-gray-100' : 'text-gray-500',
                ]"
            >
                <InformationCircleIcon class="h-5 w-5" />

                <p class="whitespace-normal">
                    {{ t('no-results') }}
                </p>
            </div>
        </slot>

        <!-- Slot - List Append -->
        <div
            v-if="slots['list-append']"
            :class="['rounded-b-md border-t p-1', dark && 'border-t-gray-600']"
            @click.capture="onSlotClick"
        >
            <slot name="list-append" />
        </div>
    </div>
</template>

<script setup lang="ts" generic="T = any">
import { onClickOutside, type MaybeElementRef } from '@vueuse/core'
import {
    nextTick,
    computed,
    ref,
    onMounted,
    onBeforeUnmount,
    useSlots,
} from 'vue'
import { CheckIcon, InformationCircleIcon } from '@heroicons/vue/24/outline'
import { get } from 'lodash-es'

const props = defineProps({
    modelValue: {
        type: [Object, String, Number] as PropType<any>,
        default: undefined,
    },
    id: {
        type: String,
        required: true,
    },
    /** Array of options to render to select's list */
    options: {
        type: Array as PropType<T[]>,
        default: () => [],
    },
    /** What property from the option object should be shown as text for each element */
    optionText: {
        type: [String, Function] as PropType<string | ((item: T) => string)>,
        default: 'value',
    },
    /** What property from the object should be used as a value for each element */
    optionValue: {
        type: String,
        default: 'value',
    },
    dark: {
        type: Boolean,
        default: false,
    },
    areItemsObjects: {
        type: Boolean,
        default: false,
    },
    hideSelectedCheck: {
        type: Boolean,
        default: false,
    },
    overlap: {
        type: Boolean,
        default: true,
    },
    /** Whether matching options should highlight the searched text
     ** 💡 Requires "filterable" prop set to true
     ** 💡 Does not work if "list-item" slot is used
     */
    highlightResults: {
        type: Boolean,
        default: false,
    },
    query: {
        type: String,
        default: '',
    },
    isInputDirty: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits<{
    (event: 'update:modelValue', value: any): void
    (event: 'close-list'): void
}>()

const slots = useSlots()
const { t } = useI18n()

const listbox = ref<HTMLUListElement | null>(null)
const highlightedOptionIndex = ref<number>()
const highlightedOption = computed(() =>
    highlightedOptionIndex.value !== undefined
        ? props.options[highlightedOptionIndex.value]
        : undefined,
)

onClickOutside(document.querySelector(`#${props.id}`) as MaybeElementRef, () =>
    emit('close-list'),
)

const listboxId = computed(() => `${props.id}-listbox`)
const highlightedAttribute = `${listboxId.value}-listbox-option-highlighted`

const innerValueComparator = computed(() => {
    if (props.areItemsObjects) {
        return get(props.modelValue, props.optionValue)
    }

    return props.modelValue
})

onMounted(() => {
    highlightInitialOptionIndex()

    nextTick(() => scrollToHighlightedOption())

    document.addEventListener('keydown', onKeyPress, true)
})

onBeforeUnmount(() => document.removeEventListener('keydown', onKeyPress, true))

function highlightInitialOptionIndex() {
    if (props.modelValue) {
        const index = props.options.findIndex((option) => {
            if (props.areItemsObjects) {
                return (
                    get(option, props.optionValue) ===
                    get(props.modelValue, props.optionValue)
                )
            }

            return option === props.modelValue
        })

        highlightedOptionIndex.value = index
    } else highlightedOptionIndex.value = 0
}

function isOptionSelected(option: any) {
    if (props.areItemsObjects) {
        return get(option, props.optionValue) === innerValueComparator.value
    }

    return option === innerValueComparator.value
}

function onKeyPress(event: KeyboardEvent) {
    switch (event.key) {
        case 'ArrowDown':
            event.preventDefault()
            handleArrowDown()
            break
        case 'ArrowUp':
            event.preventDefault()
            handleArrowUp()
            break
        case 'Enter':
            event.preventDefault()

            if (highlightedOption.value) {
                onOptionSelect(highlightedOption.value)
            } else emit('close-list')

            break
        case 'Escape':
            event.preventDefault()
            emit('close-list')
            break
    }

    nextTick(() => scrollToHighlightedOption())
}

function scrollToHighlightedOption() {
    listbox.value
        ?.querySelector(`[${highlightedAttribute}="true"]`)
        ?.scrollIntoView({ block: 'center' })
}

function getOptionText(option: T): string {
    if (props.areItemsObjects) {
        if (typeof props.optionText === 'function') {
            return props.optionText(option)
        }

        return get(option, props.optionText)
    }

    return option as string
}

function getOptionDisplayText(option: T) {
    const value = getOptionText(option)

    const eligibleForMark =
        props.highlightResults && props.query && props.isInputDirty

    if (eligibleForMark) {
        const rgx = new RegExp(props.query, 'i')
        const matched = value.match(rgx)?.[0]
        const withMark = value.replace(rgx, `<mark>${matched}</mark>`)

        return withMark
    }

    return value
}

// This would ensure that we close the select/combobox if a button/link inside of the slots gets clicked
function onSlotClick(event: Event) {
    const whitelisted = ['BUTTON', 'A']
    const triggerEl = (event.target as HTMLElement).tagName

    if (whitelisted.includes(triggerEl)) {
        emit('close-list')
    }
}

function handleArrowDown() {
    const isIndexInvalid = isHighlightedIndexInvalid(
        highlightedOptionIndex.value,
    )

    // On first arrow down, highlight the first option
    if (isIndexInvalid) {
        highlightedOptionIndex.value = 0
    } else {
        // If it overlaps the last option, go back to the first one
        if (highlightedOptionIndex.value === props.options.length - 1) {
            highlightedOptionIndex.value = props.overlap
                ? 0
                : highlightedOptionIndex.value
        } else highlightedOptionIndex.value! += 1
    }
}

function handleArrowUp() {
    const isIndexInvalid = isHighlightedIndexInvalid(
        highlightedOptionIndex.value,
    )

    // On first arrow up, highlight the last option
    if (isIndexInvalid) {
        highlightedOptionIndex.value = props.options.length - 1
    } else {
        // If it overlaps the first option, go back to the last one
        if (highlightedOptionIndex.value === 0) {
            highlightedOptionIndex.value = props.overlap
                ? props.options.length - 1
                : highlightedOptionIndex.value
        } else highlightedOptionIndex.value! -= 1
    }
}

function isHighlightedIndexInvalid(index?: number) {
    return (
        index === undefined ||
        highlightedOptionIndex.value === undefined ||
        index === -1 ||
        index === props.options.length ||
        !props.options[index]
    )
}

function onOptionSelect(option: any) {
    emit('update:modelValue', option)
}
</script>
